import React, { useContext, useEffect } from 'react'
import { useRouter } from 'next/router'
import _get from 'lodash.get'

import { RouteContext } from '@/pages/_app'
import { useAuthorizationStore } from '../authorization/store'
import { useGetActiveCompanyMembership, useGetProfile } from '../profile/api'
import SecureLogistics from '../secureLogistics/components/SecureLogistics'
import { getLocaleFromUrl } from '@/lib/locale'

interface Props {
  children: React.ReactNode
}

const RouteRender: React.FC<Props> = ({ children }) => {
  const router = useRouter()
  const value = useContext(RouteContext)
  const path = router.route.replace('/[locale]/', '').replace(/\//g, '.')
  const routeDef = _get(value, path)

  const loggedIn = useAuthorizationStore((state) => state.loggedIn)

  const { data: profile } = useGetProfile({ enabled: false })
  const { data: activeCompanyMembership } = useGetActiveCompanyMembership({
    enabled: loggedIn,
  })

  useEffect(() => {
    if (
      !loggedIn &&
      routeDef &&
      'hasLoggedOutVersion' in routeDef &&
      !routeDef.hasLoggedOutVersion
    ) {
      router.push('/')
    }
  }, [loggedIn, routeDef, router])

  if (
    !!activeCompanyMembership &&
    activeCompanyMembership.companyInactivityTypeId !== null &&
    !router.pathname.includes('de-activated-company')
  ) {
    router.push(`/${getLocaleFromUrl()}/de-activated-company`)
  }

  if (routeDef && routeDef.hasLoggedInVersion && loggedIn && !profile) {
    return <SecureLogistics loggedIn={loggedIn}>{children}</SecureLogistics>
  }

  if (
    (routeDef && routeDef.hasLoggedInVersion && loggedIn) ||
    (routeDef && routeDef.hasLoggedOutVersion) ||
    !routeDef ||
    !('hasLoggedOutVersion' in routeDef)
  ) {
    return <SecureLogistics loggedIn={loggedIn}>{children}</SecureLogistics>
  }

  return null
}

export default RouteRender
