import {
  useMutation,
  UseMutationOptions,
  UseQueryOptions,
  useQuery,
} from '@tanstack/react-query'

import api from '@/lib/api'
import { getAccessToken } from '@/lib/getAccessToken'
import {
  ActiveCompanyMembershipResponse,
  CompanyMembership,
  Profile,
  ProfileResponse,
  ProfileSettingsPayload,
  ProfileSettingsResponse,
} from './types'
import { SLSignInPayload } from '../onboarding/types'
import { updatedProfileKey } from '@/config'

export const profileQueryKey = ['profile']
export const profileSettingsQueryKey = ['profileSettings']
export const activeCompanyMembershipKey = ['activeCompanyMembership']

export const useGetProfile = (options?: UseQueryOptions) => {
  const accessToken = getAccessToken()
  return useQuery<ProfileResponse, unknown, Profile, any>({
    queryKey: profileQueryKey,
    queryFn: () =>
      api(
        `${process.env.NEXT_PUBLIC_ECT_API_URL}/RPProfiles/api/Profile/v1/profile`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Ocp-Apim-Subscription-Key': `${process.env.NEXT_PUBLIC_ECT_API_KEY}`,
          },
        },
        'STS',
      ),
    select: (data: ProfileResponse) => data.profile,
    ...options,
    enabled:
      options && typeof options.enabled === 'boolean'
        ? options.enabled && !!accessToken
        : !!accessToken,
  })
}

export const useGetActiveCompanyMembership = (options?: UseQueryOptions) => {
  const accessToken = getAccessToken()

  return useQuery<
    ActiveCompanyMembershipResponse,
    unknown,
    CompanyMembership,
    any
  >({
    queryKey: activeCompanyMembershipKey,
    queryFn: () =>
      api(
        `${process.env.NEXT_PUBLIC_ECT_API_URL}/RPProfiles/api/CompanyMembership/v1/companyMembership/active`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Ocp-Apim-Subscription-Key': `${process.env.NEXT_PUBLIC_ECT_API_KEY}`,
          },
        },
      ),
    select: (data: ActiveCompanyMembershipResponse) => data.companyMembership,
    ...options,
    enabled:
      options && typeof options.enabled === 'boolean'
        ? options.enabled && !!accessToken
        : !!accessToken,
  })
}

export const useMutatePutSLProfile = (options?: UseMutationOptions) =>
  // @ts-ignore
  useMutation<any, any, SLSignInPayload, any>({
    ...options,
    // @ts-ignore
    mutationFn: (data: SLSignInPayload) =>
      api(
        `${process.env.NEXT_PUBLIC_ECT_API_URL}/RPProfiles/api/Profile/v1/securelogistics`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
            'Ocp-Apim-Subscription-Key': `${process.env.NEXT_PUBLIC_ECT_API_KEY}`,
            'User-Id': data.userid,
            'Content-Type': 'application/json, */*',
          },
          body: JSON.stringify({
            birthDate: data.birthdate,
          }),
        },
      ),
    onSuccess: (data: any) => {
      window.localStorage.setItem(updatedProfileKey, new Date().toString())
      if (options?.onSuccess) {
        options.onSuccess(data, undefined, undefined)
      }
    },
  })

export const useMutateDeleteSLFromProfile = (options?: UseMutationOptions) =>
  useMutation({
    ...options,
    mutationFn: () =>
      api(
        `${process.env.NEXT_PUBLIC_ECT_API_URL}/RPProfiles/api/Profile/v1/securelogistics`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
            'Ocp-Apim-Subscription-Key': `${process.env.NEXT_PUBLIC_ECT_API_KEY}`,
          },
        },
        'STS',
      ),
    onSuccess: (data: any) => {
      window.localStorage.setItem(updatedProfileKey, new Date().toString())
      if (options?.onSuccess) {
        options.onSuccess(data, undefined, undefined)
      }
    },
  })

export const useGetProfileSettings = (options?: UseQueryOptions) => {
  const accessToken = getAccessToken()
  return useQuery<ProfileSettingsResponse, any, any, any>({
    queryKey: profileSettingsQueryKey,
    queryFn: () =>
      api(
        `${process.env.NEXT_PUBLIC_ECT_API_URL}/RPProfiles/api/Profile/v1/settings`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
            'Ocp-Apim-Subscription-Key': `${process.env.NEXT_PUBLIC_ECT_API_KEY}`,
          },
        },
        'STS',
      ),
    ...options,
    enabled:
      options && typeof options.enabled === 'boolean'
        ? options.enabled && !!accessToken
        : !!accessToken,
  })
}

export const useMutatePutProfileSettings = (options?: UseMutationOptions) =>
  // @ts-ignore
  useMutation<any, any, ProfileSettingsPayload, any>({
    mutationFn: (data: ProfileSettingsPayload) =>
      api(
        `${process.env.NEXT_PUBLIC_ECT_API_URL}/RPProfiles/api/Profile/v1/settings`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
            'Ocp-Apim-Subscription-Key': `${process.env.NEXT_PUBLIC_ECT_API_KEY}`,
            'Content-Type': 'application/json, */*',
          },
          body: JSON.stringify({
            languageCode: data.languageCode,
            appCategoryId: data.appCategoryId,
          }),
        },
        'STS',
      ),
    ...options,
  })
