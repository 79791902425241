import React, { useCallback, useEffect, useState } from 'react'

import { useAuthorizationStore } from '../store'
import { getAccessToken, removeAccessToken } from '@/lib/getAccessToken'
import {
  activeCompanyMembershipKey,
  profileQueryKey,
  useGetProfile,
  useMutatePutProfileSettings,
} from '@/features/profile/api'
import LoadingScreen from '@/features/shared/LoadingScreen'
import ErrorScreen from '@/features/shared/ErrorScreen'
import { queryClient } from '@/pages/_app'
import { useMutateUnlinkGroup } from '@/features/signalR/api'
import { useSignalRStore } from '@/features/signalR/store'
import { devLog } from '@/lib/development'
import { getOnboardingValues, removeOnboardingValues } from '@/lib/getAppValues'
import { updatedCompanyKey, updatedProfileKey } from '@/config'
import { useGetCompany } from '@/features/company/api'

interface Props {
  children: React.ReactNode
}

const Authorization: React.FC<Props> = ({ children }) => {
  const [retrieveProfile, setRetrieveProfile] = useState(false)

  const isLinked = useSignalRStore((state) => state.isLinked)
  const accessToken = useAuthorizationStore((state) => state.accessToken)
  const setAccessToken = useAuthorizationStore((state) => state.setAccessToken)
  const setUserStateChangedIn = useAuthorizationStore(
    (state) => state.setUserStateChangedIn,
  )
  const setLoggedIn = useAuthorizationStore((state) => state.setLoggedIn)
  const userStateChangedIn = useAuthorizationStore(
    (state) => state.userStateChangedIn,
  )
  const {
    isLoading,
    error,
    fetchStatus,
    refetch: refetchProfile,
  } = useGetProfile({
    enabled: retrieveProfile,
    onSuccess: () => {
      setRetrieveProfile(false)
      setUserStateChangedIn('idle')
      setLoggedIn(true)
    },
    onError: () => {
      setRetrieveProfile(false)
      setUserStateChangedIn('loggedOut')
    },
  })

  const { refetch: refetchCompany } = useGetCompany({ enabled: false })

  const { mutate: unlinkGroupWithNewProfile } = useMutateUnlinkGroup({
    onSuccess: () => {
      queryClient.resetQueries(profileQueryKey)
      queryClient.invalidateQueries(profileQueryKey)
      queryClient.resetQueries(activeCompanyMembershipKey)
      queryClient.invalidateQueries(activeCompanyMembershipKey)
      setRetrieveProfile(true)
    },
  })

  const handleUnlinkGroup = useCallback(() => {
    unlinkGroupWithNewProfile()
  }, [unlinkGroupWithNewProfile])

  const { mutate: putProfileSettings } = useMutatePutProfileSettings({
    onSuccess: () => removeOnboardingValues(),
  })

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.storageArea != localStorage) return
      if (
        event.key === updatedProfileKey &&
        event.newValue !== event.oldValue
      ) {
        refetchProfile()
      }
      if (
        event.key === updatedCompanyKey &&
        event.newValue !== event.oldValue
      ) {
        refetchCompany()
      }
    }
    window.addEventListener('storage', handleMessage)
  }, [refetchProfile, refetchCompany])

  // Initial on mount
  useEffect(() => {
    const accessToken = getAccessToken()

    // IF logged in
    if (!!accessToken) {
      devLog('🔒 1.1. on mount if Logged IN')
      setAccessToken(accessToken)
      setUserStateChangedIn('loggedIn')
    }

    // IF logged out
    if (!!!accessToken) {
      devLog('🔒 1.2. on mount if Logged OUT')
      setUserStateChangedIn('loggedOut')
    }
  }, [setAccessToken, setUserStateChangedIn])

  // Watch session storage for changes
  useEffect(() => {
    const handleStorageChange = () => {
      const storageAccessToken = getAccessToken()
      // If accessToken has changed
      if (accessToken !== storageAccessToken) {
        // Logged in
        if (storageAccessToken && !accessToken) {
          devLog('🔒 1 handleStorageChange')
          setAccessToken(storageAccessToken)
          setUserStateChangedIn('loggedIn')
        }
        // Logged out
        if (!storageAccessToken && accessToken) {
          devLog('🔒 2 handleStorageChange')
          setAccessToken(storageAccessToken)
          setUserStateChangedIn('loggedOut')
        }

        // Refreshed token
        if (
          storageAccessToken !== accessToken &&
          !!accessToken &&
          !!storageAccessToken
        ) {
          devLog('🔒 3 handleStorageChange')
          setAccessToken(storageAccessToken)
          setUserStateChangedIn('refreshed')
        }
      }
    }

    window.addEventListener('storage', handleStorageChange)
    return () => window.removeEventListener('storage', handleStorageChange)
  }, [accessToken, setAccessToken, setUserStateChangedIn])

  // User gets loggedIn
  useEffect(() => {
    if (userStateChangedIn == 'loggedIn') {
      devLog('🔒 3. if userStateChangedIn === loggedIn')
      if (isLinked) {
        handleUnlinkGroup()
      } else {
        setRetrieveProfile(true)
      }

      if (!!getOnboardingValues()) {
        putProfileSettings(getOnboardingValues())
      }
    }
  }, [isLinked, handleUnlinkGroup, userStateChangedIn, putProfileSettings])

  // User gets loggedOut
  useEffect(() => {
    if (userStateChangedIn == 'loggedOut') {
      devLog('🔒 4. if userStateChangedIn === loggedOut')
      setUserStateChangedIn('idle')
      setLoggedIn(false)
      removeAccessToken()
    }
  }, [isLinked, setLoggedIn, setUserStateChangedIn, userStateChangedIn])

  // User gets refreshed
  useEffect(() => {
    if (userStateChangedIn == 'refreshed') {
      devLog('🔒 5. if userStateChangedIn === refreshed')
      setRetrieveProfile(true)
    }
  }, [userStateChangedIn])

  // Profile isLoading
  if (isLoading && fetchStatus !== 'idle') {
    return <LoadingScreen />
  }

  // Profile Error
  if (error) {
    return <ErrorScreen>Error loading profile</ErrorScreen>
  }

  return <>{children}</>
}

export default Authorization
